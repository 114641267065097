import { template as template_77ee53479076430f9a2c67fc5762f909 } from "@ember/template-compiler";
const FKText = template_77ee53479076430f9a2c67fc5762f909(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
