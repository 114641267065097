import { template as template_9844ac315e2b4482b36a6d604e95e7d6 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
const CategoryTitleBefore = template_9844ac315e2b4482b36a6d604e95e7d6(`
  <PluginOutlet
    @name="category-title-before"
    @outletArgs={{hash category=@category}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryTitleBefore;
